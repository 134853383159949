import React, { useState, useEffect } from 'react';
import truncate from 'lodash/truncate';

import { getFluidGatsbyImage } from '../../utils/gatsby-storyblok-image-fixed/';
import ProductIntro from '../../components/product-intro';
import DetailsSteps from '../../components/details-steps';
import SectionTabs from '../../components/section-tabs';
import SectionTextWithButton from '../../components/section-text-with-button';
import SectionInstallation from '../../components/section-installation';
import CaseStudies from '../../components/case-studies';
import RelatedProducts from '../../components/related-products';
import RichtextParser from '../../utils/richtext-parser';

function StoryblokProductComponent({
  featuredImage,
  productGallery,
  productFeatures,
  productDetails,
  featuredCaseStudies,
  relatedProducts,
  installationInstructions,
  productName,
  userManualFile,
  installationVideoLink,
  videoLink,
  datasheetFile,
  enableLargeImageHeader,
  category,
  installationInstructionsSubtitle,
  enableCustomInstallationInstructions,
  ...rest
}) {
  const fluidFeaturedImage = !!featuredImage && getFluidGatsbyImage(featuredImage, {
    maxWidth: 1920,
  });

  const productGalleryImages = productGallery ? productGallery.map((galleryImg) => {
    return getFluidGatsbyImage(galleryImg, {
      maxWidth: 800,
    })
  }) : []

  const productFeaturesArr = productFeatures ? productFeatures.map((feature) => {
    return {
      title: feature.title,
      text: RichtextParser(feature.content),
    }
  }) : []

  const tabsTechSpecs = productDetails ? productDetails.flatMap(({ techSpecs }) => techSpecs.map((spec) => {
    return {
      text: RichtextParser(spec.content),
      ...spec,
    };
  })) : []

  const productShapes = productDetails ? productDetails.flatMap(({ shapesAndSizes }) => shapesAndSizes.map((shape) => {
    return {
      title: 'Size Guide',
      button: shape.sizeGuideFile &&
        shape.sizeGuideFile && {
          to: shape.sizeGuideFile.filename,
          children: 'Full Size Guide',
        },
      ...shape,
    };
  })) : []

  const productVideos = productDetails ? productDetails.flatMap(({ videos }) => videos.map((video) => {
    const videoThumbnailImg = getFluidGatsbyImage(video.videoThumbnail, {
      maxWidth: 500,
    });
    return {
      title: video.videoTitle,
      category: video.videoType,
      duration: video.videoLength + ' mins',
      video: {
        url: video.videoLink,
        backgroundImage: videoThumbnailImg,
      },
    }
  })) : []

  const tabsDownloads = productDetails ? productDetails.flatMap(({ downloads }) => downloads.map((download) => {
   return {
      text: download.description,
      button: {
        to: download.file.filename,
        children: download.ctaButtonText,
      },
      ...download,
    }
  })) : []

  let tabs = [];

  if (!productDetails[0].hideTechSpecs) {
    let techSpecsObj = {
      name: 'Tech Specs',
      content: [
        {
          type: 'text_content',
          items: tabsTechSpecs,
        },
      ],
    };
    tabs.push(techSpecsObj);
  }

  if (!productDetails[0].hideShapesAndSizes) {
    let shapesAndSizesObj = {
      name: 'Size Guide',
      content: [
        {
          type: 'shapes',
          items: productShapes,
          largeMode: productDetails[0].shapesAndSizes.largeDataMode,
        },
      ],
    };
    tabs.push(shapesAndSizesObj);
  }

  if (!productDetails[0].hideVideos) {
    let videosObj = {
      name: 'Videos',
      content: [
        {
          type: 'videos',
          items: productVideos,
        },
      ],
    };
    tabs.push(videosObj);
  }

  if (!productDetails[0].hideDownloads) {
    let downloadsObj = {
      name: 'Downloads',
      content: [
        {
          type: 'links',
          items: tabsDownloads,
        },
      ],
    };
    tabs.push(downloadsObj);
  }

  let caseStudiesParsed = [];
  if (
    featuredCaseStudies !== undefined &&
    featuredCaseStudies.length !== 0 &&
    featuredCaseStudies[0].caseStudies.length !== 0
  ) {
    featuredCaseStudies[0].caseStudies.map((caseStudy, i) => {
      const caseStudyImg = getFluidGatsbyImage(
        caseStudy.content.featuredImage,
        {
          maxWidth: 500,
        }
      );
      let parsedCaseStudy = {
        title: caseStudy.content.title,
        image: caseStudyImg,
        link: '/' + caseStudy.full_slug,
      };
      caseStudiesParsed.push(parsedCaseStudy);
    });
  }

  let relatedProductsParsed = [];

  relatedProducts.forEach((product) => {
    const productImg = getFluidGatsbyImage(product.content.productThumbnail, {
      maxWidth: 500,
    });
    let productParsed = {
      name: product.content.productName,
      link: '/' + product.full_slug,
      image: productImg,
    };
    relatedProductsParsed.push(productParsed);
  });

  let installationIcon1;
  let installationIcon2;
  let installationIcon3;
  let installationInstructionSteps;

  if (enableCustomInstallationInstructions) {
    let data = installationInstructions[0];
    if (
      data.stepOneIcon.filename !== '' &&
      data.stepOneIcon.filename !== null
    ) {
      installationIcon1 = getFluidGatsbyImage(data.stepOneIcon, {
        maxWidth: 500,
      });
    } else {
      installationIcon1 = getFluidGatsbyImage(
        category.content.installationStepOneIcon,
        {
          maxWidth: 500,
        }
      );
    }

    if (
      data.stepTwoIcon.filename !== '' &&
      data.stepTwoIcon.filename !== null
    ) {
      installationIcon2 = getFluidGatsbyImage(data.stepTwoIcon, {
        maxWidth: 500,
      });
    } else {
      installationIcon2 = getFluidGatsbyImage(
        category.content.installationStepTwoIcon,
        {
          maxWidth: 500,
        }
      );
    }

    if (
      data.stepThreeIcon.filename !== '' &&
      data.stepThreeIcon.filename !== null
    ) {
      installationIcon3 = getFluidGatsbyImage(data.stepThreeIcon, {
        maxWidth: 500,
      });
    } else {
      installationIcon3 = getFluidGatsbyImage(
        category.content.installationStepThreeIcon,
        {
          maxWidth: 500,
        }
      );
    }

    installationInstructionSteps = [
      {
        title: data.stepOneText,
        icon: installationIcon1,
      },
      {
        title: data.stepTwoText,
        icon: installationIcon2,
      },
      {
        title: data.stepThreeText,
        icon: installationIcon3,
      },
    ];
  } else {
    installationIcon1 = getFluidGatsbyImage(
      category.content.installationStepOneIcon,
      {
        maxWidth: 500,
      }
    );

    installationIcon2 = getFluidGatsbyImage(
      category.content.installationStepTwoIcon,
      {
        maxWidth: 500,
      }
    );

    installationIcon3 = getFluidGatsbyImage(
      category.content.installationStepThreeIcon,
      {
        maxWidth: 500,
      }
    );

    installationInstructionSteps = [
      {
        title: category.content.installationStepOneText,
        icon: installationIcon1,
      },
      {
        title: category.content.installationStepTwoText,
        icon: installationIcon2,
      },
      {
        title: category.content.installationStepThreeText,
        icon: installationIcon3,
      },
    ];
  }

  return (
    <React.Fragment>
      <ProductIntro
        pagination={[
          { link: '/products', name: 'Products' },
          {
            name: truncate(productName, { length: 50 }),
          },
        ]}
        navigationTitle={productName}
        title={productName}
        carouselImages={productGalleryImages}
        videoLink={videoLink}
        largeImageHeader={enableLargeImageHeader}
        bottomImage={fluidFeaturedImage}
      />
      <DetailsSteps
        title={'Product features and details.'}
        sections={productFeaturesArr}
      />

      {tabs && tabs.length > 0 && tabs.length < 3 ? (
        <SectionTabs extended={true} items={tabs} productName={productName} />
      ) : (
        <SectionTabs items={tabs} productName={productName} />
      )}
      {datasheetFile && (
        <SectionTextWithButton
          name={productName}
          type={'Data Sheets'}
          text={
            'For all information regarding this product please download its datasheet.'
          }
          button={{ children: 'Datasheet PDF' }}
          download
          filePath={datasheetFile.filename}
          fileType={'PDF'}
          popupTitle={
            'To get access to this <span>free</span> resource, please enter your email address.'
          }
        />
      )}

      <SectionInstallation
        name={productName}
        title={
          installationInstructions &&
          installationInstructions[0] &&
          installationInstructions[0].title
        }
        text={
          installationInstructionsSubtitle && installationInstructionsSubtitle
        }
        category={category.slug}
        installationInstructionSteps={installationInstructionSteps}
        manualUrl={userManualFile && userManualFile.filename}
        videoLink={installationVideoLink}
        hideVideoBox
      />
      {featuredCaseStudies[0] && (
        <CaseStudies
          title={featuredCaseStudies[0].title}
          text={featuredCaseStudies[0].subtitle}
          items={caseStudiesParsed}
        />
      )}

      <RelatedProducts
        title={'Related Products'}
        products={relatedProductsParsed}
      />
    </React.Fragment>
  );
}

export default StoryblokProductComponent;
